import React from "react"
import styled, { withTheme } from "styled-components"

import Container from "../layout/container"

import Person from "../../images/illustrations/person.svg"
import Amplify from "../ui/infographic"

const Steps = ({ theme }: any) => {
  const Section = styled.section`
    background: ${theme.colors.primary};
    color: ${theme.colors.light};
    padding: 5rem 0;
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      padding: 2rem 0;
    }

    h2 {
      color: ${theme.colors.secondary};
      font-size: 60px;
      line-height: 1;
      max-width: 600px;
    }

    p {
      font-weight: normal;
      font-size: 25px;
      max-width: 600px;
    }
  `

  const Wrapper = styled.div`
    display: flex;
    flex-direction: row;

    svg {
      width: 160px;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        height: 37%;
      }
    }
    picture {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-width: 160px;
      @media screen and (max-width: ${theme.breakpoints.tablet}) {
        display: none;
      }
    }
    & > div {
      max-width: 860px;
      font-size: 16px;
    }
  `

  const Infographic = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: -10rem;
    @media screen and (max-width: ${theme.breakpoints.phone}) {
      margin-top: 0;
      margin-bottom: 6rem;
    }

    svg {
      width: 100%;
    }

    div {
      width: 24%;
      padding: 1rem;
      box-sizing: border-box;
      &:first-of-type {
        margin-left: 4%;
      }
      @media screen and (max-width: ${theme.breakpoints.tablet}) {
        margin: 0 !important;
        width: 100%;
      }
    }
  `

  const Headline = styled.h3`
    display: none;
    @media screen and (max-width: ${theme.breakpoints.tablet}) {
      display: block;
      font-size: 25px;
      font-weight: normal;
      margin: 0 0 1rem;
    }
  `

  return (
    <Section id="steps">
      <Container>
        <h2>Finding your voice</h2>
        <p>
          To help CEOs find their voice, we have developed <em>amp</em> - a
          three-step programme designed to raise your profile and boost your
          brand.
        </p>
        <p>In just 180 days, we will amplify you.</p>
        <Wrapper>
          <picture>
            <Person />
          </picture>
          <Infographic>
            <Amplify />
            <div>
              <Headline>Discover</Headline>
              Understand your customers, employees and investors to spot the
              overarching and specific messages that resonate and motivate.
            </div>
            <div>
              <Headline>Craft</Headline>
              Develop your narrative into real stories that connect with your
              audience.
            </div>
            <div>
              <Headline>Tell</Headline>
              Get your story in front of people that matter to influence and
              control the discourse.
            </div>
            <div>
              <Headline>Amplify</Headline>
              Amplify and control your views, news and opinions for a consistent
              narrative for your target audience.
            </div>
          </Infographic>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default withTheme(Steps)
