import React from "react"
import styled, { withTheme } from "styled-components"

import Container, { Row, Third } from "../layout/container"
import List from "../ui/list"

const Outcomes = ({ theme }: any) => {
  const Section = styled.section`
    background: ${theme.colors.light};
    padding: 5rem 0;
    font-size: 18px;
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      padding: 2rem 0;
    }

    pre {
      font-family: ${theme.font};
      width: 100px;
      height: 100px;
      margin: 0;
      background: ${theme.colors.accent};
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: ${theme.colors.light};
      font-size: 50px;
      font-weight: bold;
      line-height: 0;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        margin: 2rem auto 0;
      }
    }

    h2 {
      color: ${theme.colors.primary};
      font-size: 60px;
      margin-bottom: 0;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        font-size: 40px;
      }
    }

    h3 {
      font-size: 30px;
      margin: 1rem 0;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        text-align: center;
      }
    }
  `

  return (
    <Section id="Outcomes">
      <Container>
        <h2>
          The <em>amp</em> effect
        </h2>
        <p>
          <em>amp</em> is geared to deliver key professional and business
          outcomes, setting you up for meaningful engagement and success with
          customers, employees and investors.
        </p>
        <Row>
          <Third>
            <pre>1</pre>
            <h3>Value</h3>
            <List>
              <li>Uncover your authentic voice and build your story</li>
              <li>Improve your communication skills</li>
              <li>Form relationships with subject matter expert journalists</li>
            </List>
          </Third>
          <Third>
            <pre>2</pre>
            <h3>Advantage</h3>
            <List>
              <li>Establish a media presence</li>
              <li>Enhance your reputation</li>
              <li>Build trust in front of your target audience</li>
            </List>
          </Third>
          <Third>
            <pre>3</pre>
            <h3>Results</h3>
            <List>
              <li>Win customers and drive loyalty</li>
              <li>Acquire and retain top talent</li>
              <li>Attract investment</li>
            </List>
          </Third>
        </Row>
      </Container>
    </Section>
  )
}

export default withTheme(Outcomes)
