import React, { useState } from "react"
import { animated, useTrail } from "react-spring"
import styled from "styled-components"
import { Waypoint } from "react-waypoint"

const Svg = styled.svg`
  .cls-1 {
    fill: none;
  }

  .cls-2 {
    fill: #dbecdf;
  }

  .cls-3 {
    fill: #e83368;
  }

  .cls-4 {
    fill: #06195d;
  }

  .cls-5 {
    fill: #dbecde;
  }

  .cls-6 {
    isolation: isolate;
  }

  .cls-7 {
    fill: #dbecdd;
  }

  .cls-8 {
    clip-path: url(#clip-path);
  }
`

const Infographic = () => {
  const [paths] = useState([
    {
      id: "Discover",
      d:
        "M241.8 463.5L42.1 588.2a222.9 222.9 0 0 1 23.7 88.1H301a456.3 456.3 0 0 0-36-165.7 459.7 459.7 0 0 0-23.2-47.1",
      class: "cls-2",
    },
    {
      id: "Craft",
      d:
        "M490 308.4L290.5 433A491.8 491.8 0 0 1 357 676.3h235a725.6 725.6 0 0 0-57.4-279.5 730.3 730.3 0 0 0-44.7-88.4",
      class: "cls-2",
    },
    {
      id: "Tell",
      d:
        "M736.7 154.2L537.5 278.7a760.4 760.4 0 0 1 49.1 96.4 759.9 759.9 0 0 1 60 301.2h235c.4-136.4-26-268.5-78.6-392.8a996.9 996.9 0 0 0-66.3-129.3",
      class: "cls-2",
    },
    {
      id: "Amplify",
      d:
        "M1071.4 170.2A1264.7 1264.7 0 0 0 983.5 0L784.2 124.5A1028.8 1028.8 0 0 1 855 261.8a1029.5 1029.5 0 0 1 81.2 414.5h235c1.5-175.9-32-346-99.8-506",
      class: "cls-3",
    },
  ])
  const [start, trigger] = useState(false)

  const trail = useTrail(paths.length, {
    opacity: start ? 1 : 0,
    config: {
      tension: 200,
    },
  })

  return (
    <React.Fragment>
      <Waypoint
        onEnter={() => trigger(true)}
        // onLeave={() => trigger(false)}
        bottomOffset="50%"
      />
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        id="amp"
        data-name="amp"
        viewBox="0 0 1171.2 685.3"
        width="900"
        height="500"
      >
        <defs>
          <clipPath id="clip-path">
            <path d="M0 0h1230.4v712.9H0z" className="cls-1" />
          </clipPath>
        </defs>
        <g id="Bars">
          {trail.map((props, i) => {
            return (
              <animated.path
                key={i}
                id={paths[i].id}
                d={paths[i].d}
                className={paths[i].class}
                style={props}
              />
            )
          })}
        </g>
        <g className="cls-6">
          <g className="cls-6">
            <path
              d="M69.6 633h13a37.4 37.4 0 0 1 8.9 1 20.6 20.6 0 0 1 7.3 3.4 16.6 16.6 0 0 1 5 6.1 20.4 20.4 0 0 1 1.9 9.1 18.5 18.5 0 0 1-1.8 8.4 18 18 0 0 1-4.8 6 21.4 21.4 0 0 1-7 3.8 27.1 27.1 0 0 1-8.4 1.3h-14zm8.6 31.1h4.5a23.8 23.8 0 0 0 5.6-.6 12 12 0 0 0 4.4-2 9.8 9.8 0 0 0 3-3.7 13.1 13.1 0 0 0 1-5.6 11 11 0 0 0-1-5 10 10 0 0 0-2.9-3.5 12.6 12.6 0 0 0-4.3-2 19 19 0 0 0-5.1-.7h-5.2z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M110.1 636.8a4.8 4.8 0 1 1 1.4 3.4 4.6 4.6 0 0 1-1.4-3.4zm.7 8.4h8.2v26.9h-8.2z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M140.5 653.1a5.8 5.8 0 0 0-4.8-2.2 4.6 4.6 0 0 0-2 .5 1.8 1.8 0 0 0-1 1.7 1.5 1.5 0 0 0 1 1.4 13.8 13.8 0 0 0 2.6.9l3.3.7a12.7 12.7 0 0 1 3.4 1.3 7.6 7.6 0 0 1 2.6 2.4 7.2 7.2 0 0 1 1 4.1 7.6 7.6 0 0 1-1.1 4.3 8.7 8.7 0 0 1-3 2.7 12.6 12.6 0 0 1-4 1.4 24 24 0 0 1-4.3.4 20.9 20.9 0 0 1-5.7-.8 10.6 10.6 0 0 1-4.8-2.8l5-5.6a8.2 8.2 0 0 0 2.5 2.2 6.9 6.9 0 0 0 3.3.7 7.6 7.6 0 0 0 2.6-.4 1.5 1.5 0 0 0 1.2-1.5 1.7 1.7 0 0 0-1-1.6 11.2 11.2 0 0 0-2.6-.9l-3.4-.7a13.9 13.9 0 0 1-3.3-1.3 7 7 0 0 1-2.6-2.3 7.2 7.2 0 0 1-1-4.1 8.2 8.2 0 0 1 1-4 8.7 8.7 0 0 1 2.5-3 11.2 11.2 0 0 1 3.7-1.5 16.6 16.6 0 0 1 4.1-.6 19.6 19.6 0 0 1 5.4.8 10 10 0 0 1 4.5 2.8z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M169 653.9a4.6 4.6 0 0 0-1.7-1.5 5 5 0 0 0-2.4-.5 6.3 6.3 0 0 0-4.9 1.8 7.8 7.8 0 0 0 0 9.9 6.3 6.3 0 0 0 5 1.8 4.5 4.5 0 0 0 2.3-.6 7.3 7.3 0 0 0 1.7-1.4l5.5 5.7a10.5 10.5 0 0 1-4.6 2.8 16.9 16.9 0 0 1-10.8-.2 14.1 14.1 0 0 1-4.8-2.8 13.4 13.4 0 0 1-3.1-4.5 15 15 0 0 1 0-11.6 13.3 13.3 0 0 1 3.1-4.4 14.3 14.3 0 0 1 4.8-2.8 17 17 0 0 1 5.8-1 17.3 17.3 0 0 1 5 .8 10.6 10.6 0 0 1 4.6 2.7z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M175.8 658.6a14.1 14.1 0 0 1 1.1-5.8 13.3 13.3 0 0 1 3.2-4.4 14.1 14.1 0 0 1 4.8-2.8 17.6 17.6 0 0 1 11.6 0 14.3 14.3 0 0 1 4.8 2.8 13.3 13.3 0 0 1 3.1 4.4 15 15 0 0 1 0 11.7 13.4 13.4 0 0 1-3.2 4.4 14.1 14.1 0 0 1-4.7 2.8 17.6 17.6 0 0 1-11.7 0 14 14 0 0 1-4.7-2.8 13.5 13.5 0 0 1-3.2-4.4 14.1 14.1 0 0 1-1.1-5.9zm8.2 0a7 7 0 0 0 1.8 5 7.3 7.3 0 0 0 9.8 0 7.8 7.8 0 0 0 0-9.9 7.3 7.3 0 0 0-9.8 0 7 7 0 0 0-1.8 5z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M206.5 645.2h9l6.7 18.3h.1l6.3-18.3h8.4l-10.6 26.9h-8.8z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M264.2 667.4a13.2 13.2 0 0 1-5 4 15 15 0 0 1-6.3 1.3 17 17 0 0 1-5.8-1 14.1 14.1 0 0 1-4.8-2.8 13.4 13.4 0 0 1-3.2-4.5 15 15 0 0 1 0-11.6 13.3 13.3 0 0 1 3.2-4.4 14.3 14.3 0 0 1 4.8-2.9 17 17 0 0 1 5.8-1 13.3 13.3 0 0 1 5.2 1 11 11 0 0 1 4 2.9 12.7 12.7 0 0 1 2.5 4.4 18 18 0 0 1 1 5.8v2.6h-19.4a6.5 6.5 0 0 0 2.2 3.8 6 6 0 0 0 4 1.4 6.3 6.3 0 0 0 3.5-.9 9.3 9.3 0 0 0 2.5-2.3zm-7-11.8a4.8 4.8 0 0 0-1.4-3.6 4.9 4.9 0 0 0-3.7-1.5 6.1 6.1 0 0 0-4.2 1.6 5 5 0 0 0-1.2 1.6 5.3 5.3 0 0 0-.5 2z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M270.7 645.2h8.3v4.3h.1a10.1 10.1 0 0 1 3.2-3.7 8 8 0 0 1 4.5-1.2h1.5a6.6 6.6 0 0 1 1.3.2v7.6a12.8 12.8 0 0 0-1.7-.4 11.5 11.5 0 0 0-1.8-.1 8.8 8.8 0 0 0-3.8.6 5 5 0 0 0-2.1 1.9 7 7 0 0 0-1 2.8 27.2 27.2 0 0 0-.2 3.7V672h-8.3z"
              className="cls-4"
            />
          </g>
        </g>
        <g className="cls-6">
          <g className="cls-6">
            <path
              d="M387.8 643.1a8.3 8.3 0 0 0-3.2-2.3 10.4 10.4 0 0 0-4.2-.9 10.7 10.7 0 0 0-8.1 3.6 12.3 12.3 0 0 0-2.4 4 15.2 15.2 0 0 0 0 10 12.5 12.5 0 0 0 2.4 4 10.6 10.6 0 0 0 3.5 2.7 10.1 10.1 0 0 0 4.3 1 9.7 9.7 0 0 0 4.7-1.2 9.4 9.4 0 0 0 3.4-3l7.1 5.3a15 15 0 0 1-6.3 5.1 19.5 19.5 0 0 1-7.8 1.7 24.3 24.3 0 0 1-8.5-1.5 19.4 19.4 0 0 1-6.6-4 18.5 18.5 0 0 1-4.4-6.6 23.8 23.8 0 0 1 0-17 18.7 18.7 0 0 1 4.4-6.4 19.4 19.4 0 0 1 6.6-4.2 24 24 0 0 1 8.5-1.4 20.8 20.8 0 0 1 3.4.3 19.4 19.4 0 0 1 3.6 1 15.6 15.6 0 0 1 3.3 1.8 13.5 13.5 0 0 1 2.9 2.7z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M399 645.2h8.3v4.3a10.1 10.1 0 0 1 3.2-3.7 8 8 0 0 1 4.6-1.2h1.5a6.6 6.6 0 0 1 1.3.2v7.6a12.8 12.8 0 0 0-1.8-.4 11.5 11.5 0 0 0-1.8-.1 8.8 8.8 0 0 0-3.7.6 5 5 0 0 0-2.1 1.9 7 7 0 0 0-1 2.8 27.2 27.2 0 0 0-.3 3.7V672H399z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M438.1 668.7h-.1a7.8 7.8 0 0 1-3.7 3 12.6 12.6 0 0 1-4.8 1 12.4 12.4 0 0 1-3.6-.5 9.2 9.2 0 0 1-3-1.6 7.6 7.6 0 0 1-2.2-2.6 8 8 0 0 1-.8-3.5 8.2 8.2 0 0 1 .9-4 7.6 7.6 0 0 1 2.3-2.6 11.5 11.5 0 0 1 3.4-1.6 24.7 24.7 0 0 1 3.8-1 39 39 0 0 1 4-.2h3.8a4.3 4.3 0 0 0-1.6-3.5 5.7 5.7 0 0 0-3.7-1.3 8.1 8.1 0 0 0-3.8.8 10 10 0 0 0-3 2.4l-4.4-4.6a15.2 15.2 0 0 1 5.4-3.2 19.4 19.4 0 0 1 6.4-1 16.7 16.7 0 0 1 6 .8 8.7 8.7 0 0 1 3.8 2.7 10.3 10.3 0 0 1 2 4.3 29 29 0 0 1 .6 6V672H438zm-2-8.4h-2.3a12.1 12.1 0 0 0-2.7.6 6 6 0 0 0-2.2 1.1 2.7 2.7 0 0 0-1 2.2 2.4 2.4 0 0 0 1.3 2.2 5.3 5.3 0 0 0 2.7.7 8.3 8.3 0 0 0 2.3-.3 7 7 0 0 0 2-1 4.5 4.5 0 0 0 1.4-1.5 4.4 4.4 0 0 0 .5-2.2v-1.8z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M454 651.9h-5.5v-6.7h5.5v-4.7a22 22 0 0 1 .4-4.2 8.5 8.5 0 0 1 1.5-3.4 7.3 7.3 0 0 1 3.2-2.4 14.4 14.4 0 0 1 5.4-.8h2.5a16.9 16.9 0 0 1 2.4.5l-.4 7a10.2 10.2 0 0 0-1.4-.5 6.4 6.4 0 0 0-1.3 0 4.5 4.5 0 0 0-3 .8c-.7.5-1 1.7-1 3.5v4.2h6v6.6h-6v20.3H454z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M491.5 651.9h-7.3v9a15.6 15.6 0 0 0 .1 2 4 4 0 0 0 .5 1.5 2.5 2.5 0 0 0 1.2 1 5.2 5.2 0 0 0 2.1.3 14.9 14.9 0 0 0 1.8-.1 3 3 0 0 0 1.6-.6v6.9a12 12 0 0 1-2.9.6 26.5 26.5 0 0 1-3 .2 16.2 16.2 0 0 1-3.8-.4 8.6 8.6 0 0 1-3-1.5 6.5 6.5 0 0 1-2.1-2.5 8.5 8.5 0 0 1-.8-3.7v-12.7h-5.3v-6.7h5.3v-8h8.3v8h7.3z"
              className="cls-4"
            />
          </g>
        </g>
        <g className="cls-6">
          <g className="cls-6">
            <path
              d="M660.8 640.6h-11.2V633h31v7.6h-11.2v31.5h-8.6z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M704.8 667.4a13.2 13.2 0 0 1-5 4 15 15 0 0 1-6.3 1.3 17 17 0 0 1-5.8-1 14.1 14.1 0 0 1-4.8-2.8 13.4 13.4 0 0 1-3.1-4.5 15 15 0 0 1 0-11.6 13.3 13.3 0 0 1 3.1-4.4 14.3 14.3 0 0 1 4.8-2.9 17 17 0 0 1 5.8-1 13.3 13.3 0 0 1 5.2 1 11 11 0 0 1 4 2.9 12.8 12.8 0 0 1 2.5 4.4 18 18 0 0 1 1 5.8v2.6h-19.4a6.6 6.6 0 0 0 2.2 3.8 6.1 6.1 0 0 0 4 1.4 6.3 6.3 0 0 0 3.5-.9 9.3 9.3 0 0 0 2.5-2.4zm-7-11.8a4.8 4.8 0 0 0-1.4-3.6 4.9 4.9 0 0 0-3.7-1.5 6.1 6.1 0 0 0-4.2 1.6 5 5 0 0 0-1.2 1.6 5.3 5.3 0 0 0-.5 1.9z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path d="M711.4 630.3h8.3v41.8h-8.3z" className="cls-4" />
          </g>
          <g className="cls-6">
            <path d="M726.1 630.3h8.3v41.8h-8.3z" className="cls-4" />
          </g>
        </g>
        <g className="cls-6">
          <g className="cls-6">
            <path
              d="M955.6 633h7.1l17 39.1H970l-3.4-8.3h-15.2l-3.2 8.3h-9.5zm3.3 11.4l-4.8 12.1h9.6z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M983 645.2h8v3.7a6.7 6.7 0 0 1 1.1-1.6 8.5 8.5 0 0 1 1.7-1.3 9.8 9.8 0 0 1 2.3-1 9.4 9.4 0 0 1 2.7-.4 10.5 10.5 0 0 1 5 1.1 7.3 7.3 0 0 1 3.3 3.7 9 9 0 0 1 3.5-3.7 10.5 10.5 0 0 1 5-1.1 10.2 10.2 0 0 1 4.5.9 7.5 7.5 0 0 1 3 2.5 10.6 10.6 0 0 1 1.5 3.7 21.2 21.2 0 0 1 .5 4.5v15.9h-8.3v-15.7a6.3 6.3 0 0 0-.8-3.2 3 3 0 0 0-2.8-1.3 5.5 5.5 0 0 0-2.4.4 3.9 3.9 0 0 0-1.6 1.3 5.4 5.4 0 0 0-.8 2 11 11 0 0 0-.3 2.3V672h-8.2v-14.2l-.1-1.8a6.9 6.9 0 0 0-.4-2 3.8 3.8 0 0 0-1-1.6 3.2 3.2 0 0 0-2.2-.7 5.1 5.1 0 0 0-2.6.6 4 4 0 0 0-1.5 1.6 6 6 0 0 0-.7 2.2 17.3 17.3 0 0 0-.2 2.5v13.4H983z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M1031.8 645.2h7.6v3.6h.1a9 9 0 0 1 1.3-1.5 10.2 10.2 0 0 1 1.9-1.4 11.4 11.4 0 0 1 2.3-1 9.2 9.2 0 0 1 2.7-.3 13.8 13.8 0 0 1 5.4 1 12.1 12.1 0 0 1 4.2 2.8 13 13 0 0 1 2.7 4.4 16.2 16.2 0 0 1 1 5.6 16.9 16.9 0 0 1-1 5.4 14.8 14.8 0 0 1-2.3 4.5 12 12 0 0 1-3.9 3.2 10.9 10.9 0 0 1-5.1 1.2 14.2 14.2 0 0 1-4.9-.8 7.7 7.7 0 0 1-3.6-2.7h-.1v15.4h-8.3zm7.6 13.4a7 7 0 0 0 1.7 5 7.3 7.3 0 0 0 9.8 0 7.8 7.8 0 0 0 0-9.9 7.3 7.3 0 0 0-9.8 0 7 7 0 0 0-1.7 5z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path d="M1066.1 630.3h8.3v41.8h-8.3z" className="cls-4" />
          </g>
          <g className="cls-6">
            <path
              d="M1080.2 636.8a4.8 4.8 0 1 1 1.4 3.4 4.6 4.6 0 0 1-1.4-3.4zm.7 8.4h8.3v26.9h-8.3z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M1098.3 651.9h-5.5v-6.7h5.5v-4.7a22 22 0 0 1 .4-4.2 8.5 8.5 0 0 1 1.5-3.4 7.3 7.3 0 0 1 3.1-2.4 14.4 14.4 0 0 1 5.5-.8h2.5a17 17 0 0 1 2.3.5l-.4 7a10.2 10.2 0 0 0-1.3-.5 6.4 6.4 0 0 0-1.4 0 4.5 4.5 0 0 0-3 .8c-.6.5-1 1.7-1 3.5v4.2h6.2v6.6h-6.2v20.3h-8.3z"
              className="cls-4"
            />
          </g>
          <g className="cls-6">
            <path
              d="M1133 676.4c-.6 1.5-1 2.7-1.6 3.8a9.1 9.1 0 0 1-2 2.8 7.6 7.6 0 0 1-3 1.7 16.2 16.2 0 0 1-4.8.6 19.5 19.5 0 0 1-6.2-1l1.1-6.8a9.5 9.5 0 0 0 3.9.9 7.1 7.1 0 0 0 2.2-.3 3.9 3.9 0 0 0 1.5-1 5.2 5.2 0 0 0 1-1.3l.8-2 .6-1.5-11.7-27h9l6.8 17.7h.1l6-17.8h8.5z"
              className="cls-4"
            />
          </g>
        </g>
      </Svg>
    </React.Fragment>
  )
}

export default Infographic
