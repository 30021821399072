import React, { useState } from "react"
import styled from "styled-components"
import { useSpring } from "react-spring"
import { Waypoint } from "react-waypoint"

import Container, { Half, Row } from "../layout/container"
import Graph from "../ui/graph"

import Illustration from "../../images/illustrations/about.svg"

const Section = styled.section`
  padding: 5rem 0;
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 0;
  }
`

const Spacing = styled.div`
  height: 3rem;
`

const Video = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 5rem;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

const About = () => {
  const [first, setFirst] = useState<any>(false)
  const [second, setSecond] = useState<any>(false)

  const firstAnimation = useSpring({
    stroke: first ? "76" : "0",
    otherStroke: first ? "76, 100" : "0,100",
  })

  const secondAnimation = useSpring({
    stroke: second ? "53" : "0",
    otherStroke: second ? "53, 100" : "0,100",
  })

  return (
    <Section id="why">
      <Container>
        <Video>
          <iframe src="https://player.vimeo.com/video/355530258" />
        </Video>
      </Container>
      <Container>
        <h2>We're living in a trust crisis</h2>
        <Row>
          <Half>
            <p>
              Expectations for leaders have changed. Hiding behind a brand is no
              longer an option for a CEO. You need to lead it.
            </p>
            <p>
              The CEO must be the trusted, passionate voice of the company on
              important issues such as the company’s greater purpose and
              long-term strategy.
            </p>
            <p>
              Positioning the CEO as a thought leader creates a competitive
              edge, magnifies trust and builds customer loyalty to ultimately
              boost revenue.
            </p>
          </Half>
          <Half>
            <Illustration />
          </Half>
        </Row>
        <Spacing />
        <Row>
          <Half>
            <Waypoint onEnter={() => setFirst(true)} bottomOffset="30%" />
            <Graph
              stroke={firstAnimation.stroke.interpolate((x: any) =>
                Math.round(x)
              )}
              otherStroke={firstAnimation.otherStroke}
              caption="of people think CEOs need to step up, speak up and lead change"
              source="Edelman 2019"
            />
          </Half>
          <Half>
            <Waypoint onEnter={() => setSecond(true)} bottomOffset="30%" />
            <Graph
              stroke={secondAnimation.stroke.interpolate((x: any) =>
                Math.round(x)
              )}
              otherStroke={secondAnimation.otherStroke}
              caption="don’t trust businesses in the UK"
            />
          </Half>
        </Row>
      </Container>
    </Section>
  )
}

export default About
