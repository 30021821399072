import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

import Home from "../components/sections/home"
import Why from "../components/sections/why"
import Steps from "../components/sections/steps"
import AmpEffect from "../components/sections/amp-effect"
import Cta from "../components/ui/cta"

const IndexPage = () => {
  return (
    <Layout headerClass="home">
      <SEO
        title="CEO amp: the programme to get your business heard"
        description="Bringing together Raconteur, The Times and freuds, amp is a three-step CEO programme to improve communication, boost media presence and enhance reputation."
      />
      <Home />
      <Why />
      <Steps />
      <AmpEffect />
      <Cta />
    </Layout>
  )
}

export default IndexPage
