import React from "react"
import styled, { withTheme } from "styled-components"

import Container from "../layout/container"
import Brands from "../ui/brands"

const Home = ({ theme }: any) => {
  const Section = styled.section`
    background: ${theme.colors.primary};
    color: ${theme.colors.light};
    margin-top: -2rem;
    height: 90vh;
    min-height: 768px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: ${theme.colors.secondary};
      max-width: 850px;
      margin: 0 auto 2rem;
      padding-top: 1.5rem;
      font-size: 70px;
      text-align: center;
      line-height: 1;
      border-top: 1px solid ${theme.colors.light};
      @media screen and (max-width: ${theme.breakpoints.phone}) {
        font-size: 40px;
        padding: 1.5rem 1rem 0;
        margin-bottom: 1.5rem;
      }
    }
    p {
      max-width: 850px;
      text-align: center;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 5rem 1.5rem;
      border-bottom: 1px solid ${theme.colors.light};
      @media screen and (max-width: ${theme.breakpoints.phone}) {
        padding: 0 0 1.5rem;
      }
    }
  `

  return (
    <Section id="home">
      <Container>
        <h1>Get your business heard</h1>
        <p>
          Introducing the CEO amplifier, a three-step accelerator programme to
          enhance your profile and boost your business
        </p>
        <Brands />
      </Container>
    </Section>
  )
}

export default withTheme(Home)
